import { getLabIdFromLabUrl } from '../utils/helpers';
import { createLabRedirectUrl } from '../utils/urls';

export const transformLabsToDisplay = (
    loadedLabs,
    contentData,
    titleRegex,
    langLocale,
    courseTitle
) => {
    return labUrl => {
        const labId = getLabIdFromLabUrl(labUrl);
        const position = loadedLabs.findIndex(
            activeTraining =>
                activeTraining.labUrl === labUrl ||
                activeTraining.labId === labId
        );
        const { arn } = position > -1 ? loadedLabs[position] : {};
        const title = contentData[arn]?.title.replace(titleRegex, '');
        const redirectUrl = createLabRedirectUrl({
            labUrl,
            contentId: arn,
            langLocale,
            courseTitle,
        });
        return {
            title,
            labUrl: redirectUrl,
            labId,
            arn,
            position,
        };
    };
};
