import environment from '../.config/environment';
import { recordMetrics } from './analytics';

export const labUrlTransformer = ({
    labUrl,
    langLocale,
    classroomId,
    sessionId,
}) => {
    const url = new URL(labUrl);
    if (langLocale && !url.searchParams.has('locale')) {
        url.searchParams.append('locale', langLocale);
    }
    if ((classroomId || sessionId) && !url.searchParams.has('reference')) {
        url.searchParams.append(
            'reference',
            [classroomId, sessionId].filter(el => el).join('::')
        );
    }
    return url.toString();
};

const shouldUseLegacyLab = globals => {
    const {
        HOST_NAME: { EVENT_HORIZON },
        featureFlags: { useEventHorizon },
    } = environment(globals);
    if (!EVENT_HORIZON || !useEventHorizon) return true;
    const search = new URLSearchParams(globals.location.href.split('?')[1]);
    const useLegacy = !!search.get('legacy');
    if (useLegacy) {
        recordMetrics('eh-migration', 'AppMetrics', {
            metrics: { statlerFallback: 1 },
        });
    }
    return useLegacy;
};

export const isAllowedCourseForEventHorizon = courseTitle =>
    ['aws security essentials'].some(title =>
        courseTitle.toLowerCase().includes(title)
    );

const createEventHorizonLabUrl = ({ contentId, langLocale }, globals) => {
    const {
        HOST_NAME: { EVENT_HORIZON },
    } = environment(globals);
    const encodedContentId = encodeURIComponent(contentId);

    return `${EVENT_HORIZON}/sa/lab/${encodedContentId}/${langLocale}`;
};

export const createLabRedirectUrl = (
    {
        labUrl,
        classroomId,
        sessionId,
        contentId,
        langLocale = 'en-US',
        courseTitle,
    },
    globals = window
) => {
    const statlerLabUrl = labUrlTransformer({
        labUrl,
        classroomId,
        sessionId,
        langLocale,
    });
    if (
        shouldUseLegacyLab(globals) ||
        !isAllowedCourseForEventHorizon(courseTitle)
    ) {
        return statlerLabUrl;
    }
    if (!contentId) {
        recordMetrics('eh-migration', 'AppMetrics', {
            metrics: {
                missingContentId: 1,
                langLocale,
            },
        });
        return statlerLabUrl;
    }
    return createEventHorizonLabUrl({ contentId, langLocale }, globals);
};
